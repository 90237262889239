export const getFaqsList = [
    {
        question: "Am I supposed to collect physical copy of the pass, if bought on the website?",
        answer: `<p>
            Yes.
            <br/>Physical passes are mandatory for entry on campus.
            <br/>Digital passes bought from our website can be validated for physical passes at the following locations.
            <ul>
                <li><p><strong>At the Venue</strong> (Ticket Window)</p></li>
                <li><p><strong>At Cafe Mocha, Bodakdev</strong> <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
                <li><p><strong>At Cafe Mocha, Gulbai Tekra</strong> <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
                <li><p><strong>At Cafe Mocha, InfoCity Club</strong> <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
            </ul>
        </p>`
    },
    {
        question: "How can we avail discounts? ",
        answer: "<p>Early bird passes are <span class='scratch'>2200</span> 1800!</p>"
    },
    {
        question: "What are the timings for Mandli?  ",
        answer: "<p>The Madness of Mandli never ends.. <br/>Garba starts at 8.30pm all 10 days.</p>"
    },
    {
        question: "How to purchase passes?",
        answer: `<p>
            Passes shall be available 
            <br/><strong>Online :</strong> 
            <ul>
                <li><p>( on our own website - <a href='https://commoneventsplanningteam.com/buy-now'>'Mandli'</a> )</li>
            </ul>
            <br/><p><strong>Offline :</p></strong>
            <ul>
                <li><p>At the Venue (Ticket Window)</p></li>
                <li><p>At Cafe Mocha, Bodakdev <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
                <li><p>At Cafe Mocha, Gulbai Tekra <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
                <li><p>At Cafe Mocha, InfoCity Club <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
            </ul>
        </p>`
    },
    {
        question: "Where can I buy season passes from?",
        answer: `<p>
            Passes shall be available 
            <br/><strong>Online :</strong> 
            <ul>
                <li><p>( on our own website - <a href='https://commoneventsplanningteam.com/buy-now'>'Mandli'</a> )</li>
            </ul>
            <br/><p><strong>Offline :</strong></p>
            <ul>
                <li><p>At the Venue (Ticket Window)</p></li>
                <li><p>At Cafe Mocha, Bodakdev <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
                <li><p>At Cafe Mocha, Gulbai Tekra <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
                <li><p>At Cafe Mocha, InfoCity Club <br/>( 30th Sept to 2nd Oct | 12pm to 9pm )</p></li>
            </ul>
        </p>`
    },
    {
        question: "Can we buy passes at the venue? ",
        answer: "<p>Yes!</p>"
    },
    {
        question: "Where can I find my purchased passes? ",
        answer: "<p>E-ticket shall be sent to you via your registered email id.</p>"
    },
    {
        question: "Any couples entries? ",
        answer: "<p>No, all passes to be purchased individually.</p>"
    },
    {
        question: "Are passes required for children?",
        answer: "<p>Yes, children aged 10 years and above are required to purchase passes.</p>"
    },
];