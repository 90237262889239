import React from 'react'
import './Error.css'
import wheel from '../../Images/Mandli2024/MainBg2024.png'
import mandliYellow from '../../Images/Mandli/mandli-yellow.svg'
import { NavLink, useNavigate } from 'react-router-dom'

function Error() {
    const navigate = useNavigate();
    return (
        <div className='comming-soon error'>
            <div class="mandli">
                <img src={mandliYellow} width="250px" style={{margin: "0 0 20px"}} alt="Mandli Logo" />
                <p>404 Not Found</p>
                <NavLink className="button-86 cst-font-size" to="/buy-now" onClick={() => navigate(-1)} ><span>Go Back</span></NavLink>
            </div>
            <div class="wheel">
                <img src={wheel} alt="Animation" />
            </div>
        </div>
    )
}

export default Error 