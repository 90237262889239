// const baseUrl = "https://mandli1.ahura.xyz/api"
const baseUrl = "https://api.commoneventsplanningteam.com/api"
// const baseUrl = "http://192.168.1.15:3001/api"
// const baseUrl = "http://loacalhost:3001/api"

const pdfUrl = "https://api.commoneventsplanningteam.com/api"
// const pdfUrl = "http://192.168.1.19:3001/api"
// User APIs
export const getUserLogin = `${baseUrl}/user/userLogin`  //POST
export const getUserSignup = `${baseUrl}/user/userSignup`  //POST
export const getOpt = `${baseUrl}/user/generateOtp`  //POST
export const getVerifyOtp = `${baseUrl}/user/verifyOtp`  // POST
export const getForgotPassword = `${baseUrl}/user/forget-password`  // PATCH
export const getAuthOtp = `${baseUrl}/user/authOtp`  // POST
export const getVerifyauthOtp = `${baseUrl}/user/verifyauthOtp`  // POST
export const getValidateEmailPhone = `${baseUrl}/user/validateEmailPhone`  // POST
export const getValidateEmailPhoneMisMatch = `${baseUrl}/user/validateEmailPhoneMisMatch`  // POST

export const getPatchUserDetails = `${baseUrl}/user/userDetails` // PARCH
export const getUserDetails = `${baseUrl}/user/GetUserDetails` // GET
export const getUserUpdate = `${baseUrl}/user/editUser` // PUT

export const getUserTicketOrder = `${baseUrl}/ticketorder/GetUserTicketsOrderList` // GET
export const getUserTicketsOrderDetails = `${baseUrl}/ticketorder/GetUserTicketsOrderDetails` // GET

export const createTicketOrder = `${baseUrl}/ticketorder/ticketingSystem` // GET
export const getPaymentDetails = `${baseUrl}/payment/getpaymentdetails` // GET
export const getTicketsList = `${baseUrl}/ticket/getTicketsList`  // GET

export const getWebMaintenance = `${baseUrl}/cms/GetWebCmsList/`  // GET

export const getCreateSupport = `${baseUrl}/support/CreateSupportTicket`  // GET
export const getResendEmail = `${pdfUrl}/ticketorder/ResendEmail` // GET

// Admin APIs
export const getAdminLogin = `${baseUrl}/admin/adminLogin`  // POST
export const getAdminOpt = `${baseUrl}/admin/generateOtp`  // POST
export const getAdminVerifyOtp = `${baseUrl}/admin/verifyOtp`  // POST
export const getAdminForgotPassword = `${baseUrl}/admin/forget-password` // PATCH

export const getDashboardStats = `${baseUrl}/dashboard` // GET

export const getAdminDetails = `${baseUrl}/admin` // PATCH
export const getAdminDetail = `${baseUrl}/admin/patchAdmin`  // PATCH

export const getTicketsListAdmin = `${baseUrl}/ticket/getTicketsListForAdmin`  // GET
export const getAdminCreateTicket = `${baseUrl}/ticket/CreateTicket`  // POST
export const getAdminEditTicket = `${baseUrl}/ticket/editTicket`  // PUT

export const getUserList = `${baseUrl}/user/getUserListing` // GET
export const getEnableDisableUser = `${baseUrl}/user/enableDisableUser` // PUT
export const getUserOrderAdmin = `${baseUrl}/user/getUserOrder` // PUT
export const getUserDetailsAdmin = `${baseUrl}/user/GetUserDetails` // PUT

export const getOrderList = `${baseUrl}/ticketorder/Getorderslist`  // GET
export const getProcessedorderslist = `${baseUrl}/ticketorder/Getprocessedorderslist`  // GET
export const getUnprocessedorderslist = `${baseUrl}/ticketorder/GetUnprocessedorderslist`  // GET
export const AdmingetUserOrderDetails = `${baseUrl}/ticketorder/GetOrderDetails` // PUT
export const getEnableDisableTicket = `${baseUrl}/ticket/enableDisableTicket`  //PUT

export const getAccessList = `${baseUrl}/admin/getaccessList` // GET

export const getMembersList = `${baseUrl}/admin/getMember` // GET
export const getAddMember = `${baseUrl}/admin/addMember` // POST
export const getEditMember = `${baseUrl}/admin/editMember` // PUT
export const getDeleteMember = `${baseUrl}/admin/deleteMember` // DELETE
export const getMemberOrders = `${baseUrl}/admin/getMemberOrder` // DELETE
export const getAppMemberOrders = `${baseUrl}/scanTicket` // DELETE

export const getPhaseList = `${baseUrl}/ticket/GetPhaseList` // GET
export const getEnableDisablePhase = `${baseUrl}/ticket/enableDisablePhase` // PUT
export const getCreatePhase = `${baseUrl}/ticket` // POST
export const getEditPhase = `${baseUrl}/ticket/EditPhase` // PUT

export const getSoldTicketList = `${baseUrl}/ticketorder/GetSoldTicketList` // PUT
export const getdownloadCsv = `${baseUrl}/ticketorder/downloadCsv` // GET
export const CreateCashOrder = `${baseUrl}/ticketorder/createCashTicket`  // POST
export const AdminGetUserTicketsOrderList = `${baseUrl}/ticketorder/AdminGetUserTicketsOrderList`  // POST


// Get Support List
export const getSupportList = `${baseUrl}/support/GetSupportTicketsList/`

// Website Maintance CMS
export const getWebCMSList = `${baseUrl}/cms/GetWebCmsList/`  // GET
export const CreateWebCms = `${baseUrl}/cms/CreateWebCms/`  //POST
export const UpdateWebCms = `${baseUrl}/cms/enableDisableWebCms`  // PUT
export const EditWebCms = `${baseUrl}/cms/editWebCms`  // PUT
export const DeleteWebCms = `${baseUrl}/cms/deleteWeb`  // DELETE

// Next Coming Phase CMS
export const CreatePhaseCms = `${baseUrl}/cms/CreateCms/`  //POST
export const getCmsList = `${baseUrl}/cms/`  // GET
export const UpdatePhaseCms = `${baseUrl}/cms/enableDisableCms`  // PUT
export const EditPhaseCms = `${baseUrl}/cms/editCms`  // PUT
export const DeletePhaseCms = `${baseUrl}/cms/delete`  // DELETE


// Orders
export const getCreatePreEventOrder = `${baseUrl}/ticketorder/createCashTicket`
export const getPreEventOrderList = `${baseUrl}/ticketorder/getFreeUpiOrderList`
export const getUserFreeCashOrderDetails = `${baseUrl}/ticketorder/GetUserFreeCashOrderDetails`

export const getOngoingEvent = `${baseUrl}/OngoingEvent/`