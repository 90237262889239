import React, { useState } from "react";
import "./Formats.css"
import installation_01 from '../../Images/2023/IN_001.jpg'
import installation_02 from '../../Images/2023/IN_002.jpg'
import installation_03 from '../../Images/2023/IN_003.jpg'
import installation_04 from '../../Images/2023/IN_004.jpg'
import installation_05 from '../../Images/2023/IN_005.jpg'
import installation_06 from '../../Images/2023/IN_006.jpg'
import installation_07 from '../../Images/2023/IN_007.jpg'
import installation_08 from '../../Images/2023/IN_008.jpg'
import installation_09 from '../../Images/2023/IN_009.jpg'
import installation_10 from '../../Images/2023/IN_0010.jpg'
import installation_11 from '../../Images/2023/IN_0011.jpg'
import installation_12 from '../../Images/2023/IN_0012.jpg'
import installation_13 from '../../Images/2023/IN_0013.jpg'
import installation_14 from '../../Images/2023/IN_0014.jpg'
import installation_15 from '../../Images/2023/IN_0015.jpg'
import installation_16 from '../../Images/2023/IN_0016.jpg'
import installation_17 from '../../Images/2023/IN_0017.jpg'
import installation_18 from '../../Images/2023/IN_0018.jpg'
import installation_19 from '../../Images/2023/IN_0019.jpg'
import installation_20 from '../../Images/2023/IN_0020.jpg'
import installation_21 from '../../Images/2023/IN_0021.jpg'
import installation_22 from '../../Images/2023/IN_0022.jpg'

import event_01 from '../../Images/2023/EV_001.jpg'
import event_02 from '../../Images/2023/EV_002.jpg'
import event_03 from '../../Images/2023/EV_003.jpg'
import event_04 from '../../Images/2023/EV_004.jpg'
import event_05 from '../../Images/2023/EV_005.jpg'
import event_06 from '../../Images/2023/EV_006.jpg'
import event_07 from '../../Images/2023/EV_007.jpg'
import event_08 from '../../Images/2023/EV_008.jpg'
import event_09 from '../../Images/2023/EV_009.jpg'
import event_10 from '../../Images/2023/EV_0010.jpg'
import event_11 from '../../Images/2023/EV_0011.jpg'
import event_12 from '../../Images/2023/EV_0012.jpg'
import event_13 from '../../Images/2023/EV_0013.jpg'
import event_14 from '../../Images/2023/EV_0014.jpg'
import event_15 from '../../Images/2023/EV_0015.jpg'
import event_16 from '../../Images/2023/EV_0016.jpg'
import event_17 from '../../Images/2023/EV_0017.jpg'
import event_18 from '../../Images/2023/EV_0018.jpg'
import event_19 from '../../Images/2023/EV_0019.jpg'
import event_20 from '../../Images/2023/EV_0020.jpg'
import event_21 from '../../Images/2023/EV_0021.jpg'
import event_22 from '../../Images/2023/EV_0022.jpg'
import event_23 from '../../Images/2023/EV_0023.jpg'
import event_24 from '../../Images/2023/EV_0024.jpg'
import event_25 from '../../Images/2023/EV_0025.jpg'
import event_26 from '../../Images/2023/EV_0026.jpg'
import event_27 from '../../Images/2023/EV_0027.jpg'
import event_28 from '../../Images/2023/EV_0028.jpg'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Masonry from "react-masonry-css";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const installation_images = [
    { id: 21, original: installation_01, },
    { id: 22, original: installation_02, },
    { id: 23, original: installation_03, },
    { id: 24, original: installation_04, },
    { id: 25, original: installation_05, },
    { id: 26, original: installation_06, },
    { id: 27, original: installation_07, },
    { id: 28, original: installation_08, },
    { id: 29, original: installation_09, },
    { id: 210, original: installation_10, },
    { id: 211, original: installation_11, },
    { id: 212, original: installation_12, },
    { id: 213, original: installation_13, },
    { id: 214, original: installation_14, },
    { id: 215, original: installation_15, },
    { id: 216, original: installation_16, },
    { id: 217, original: installation_17, },
    { id: 218, original: installation_18, },
    { id: 219, original: installation_19, },
    { id: 220, original: installation_20, },
    { id: 221, original: installation_21, },
    { id: 222, original: installation_22, }
];

const event_images = [
    { id: 11, original: event_01, },
    { id: 12, original: event_02, },
    { id: 13, original: event_03, },
    { id: 14, original: event_04, },
    { id: 15, original: event_05, },
    { id: 16, original: event_06, },
    { id: 17, original: event_07, },
    { id: 18, original: event_08, },
    { id: 19, original: event_09, },
    { id: 110, original: event_10, },
    { id: 111, original: event_11, },
    { id: 112, original: event_12, },
    { id: 113, original: event_13, },
    { id: 114, original: event_14, },
    { id: 115, original: event_15, },
    { id: 116, original: event_16, },
    { id: 117, original: event_17, },
    { id: 118, original: event_18, },
    { id: 119, original: event_19, },
    { id: 120, original: event_20, },
    { id: 121, original: event_21, },
    { id: 122, original: event_22, },
    { id: 123, original: event_23, },
    { id: 124, original: event_24, },
    { id: 125, original: event_25, },
    { id: 126, original: event_26, },
    { id: 127, original: event_27, },
    { id: 128, original: event_28, },
];

function Formats() {

    Fancybox.bind("[data-fancybox]", {});

    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 2,
    };

    const shuffleArray = (array) => {
        return array
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
    };

    const allImages = shuffleArray([...installation_images, ...event_images]);

    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div className="formate-block">
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="All" value="1" />
                            <Tab label="Event" value="2" />
                            <Tab label="Installation" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {allImages.map((image) => (
                                <div className="galleryColumn" key={image.id}>
                                    <a href={image.original} data-fancybox data-caption={image.caption}>
                                        <img src={image.original} alt={image.caption} loading="lazy" />
                                    </a>
                                </div>
                            ))}
                        </Masonry>
                    </TabPanel>
                    <TabPanel value="2">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {event_images.map((image) => (
                                <div className="galleryColumn" key={image.id}>
                                    <a href={image.original} data-fancybox data-caption={image.caption}>
                                        <img src={image.original} alt={image.caption} loading="lazy" />
                                    </a>
                                </div>
                            ))}
                        </Masonry>
                    </TabPanel>
                    <TabPanel value="3">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {installation_images.map((image) => (
                                <div className="galleryColumn" key={image.id}>
                                    <a href={image.original} data-fancybox data-caption={image.caption}>
                                        <img src={image.original} alt={image.caption} loading="lazy" />
                                    </a>
                                </div>
                            ))}
                        </Masonry>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default Formats