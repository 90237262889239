// import React from 'react';
import React, { useEffect, useState } from 'react';
import "./Home2024.css"
import "./Home.css"
import { NavLink } from "react-router-dom";
import Popup from '../../Components/Popup/Popup';
import img01 from '../../Images/2023/EV_001.jpg'
import img02 from '../../Images/2023/IN_001.jpg'
import img03 from '../../Images/2023/EV_005.jpg'
import img04 from '../../Images/2023/IN_0019.jpg'
import img05 from '../../Images/2023/EV_001.jpg'
import img06 from '../../Images/2023/IN_006.jpg'
import img07 from '../../Images/2023/EV_002.jpg'
import img08 from '../../Images/2023/IN_007.jpg'
import img09 from '../../Images/2023/EV_009.jpg'
import img10 from '../../Images/2023/IN_0016.jpg'
import 'swiper/css';
import ImageSlider from '../../Components/ImageSlider/ImageSlider';
import { MdOutlineArrowOutward } from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";
import { getFaqsList } from '../../Helper/faqsList';


function Home2024() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // faq
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const answers = document.querySelectorAll('.faq-ans');
    answers.forEach((answer, idx) => {
      if (idx === activeIndex) {
        answer.style.height = answer.scrollHeight + 'px';
      } else {
        answer.style.height = '0';
      }
    });
  }, [activeIndex]);

  // Function to toggle the FAQ visibility
  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      const answer = document.querySelector(`.faq-ans[data-index="${index}"]`);
      answer.style.height = answer.scrollHeight + 'px';
      setTimeout(() => {
        answer.style.height = '0';
      }, 300);
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className='Home2024'>
      <Popup />


      <section className="section banner hideinmobile" id='banner'>
        <div className='container'>
          <div className='main-heading cst-font-spc-bt banner-content og'>
            <div className='banner-text'>
              <h1 className='titleDesktop pb-0 ind_text_swd'><span>Mandli Garba</span> <br /> </h1>
              <h3 className='ind_text_swd'>Navratri 2024 at its best</h3>
            </div>
          </div>
          <div className="mandlinfo">
            <div className='main-heading'>
              <h3 className="subheading ind_text_swd">dates</h3>
              <p className="clr_change_text ind_text_swd lh-base">3<sup>rd</sup> to 12<sup>th</sup> Oct 2024<br />08:00 PM onwards</p>
            </div>
            <div className='main-heading'>
              <h3 className="subheading ind_text_swd">venue</h3>
              <div className="mxcontetn"><NavLink to="https://maps.app.goo.gl/A5rYKSRo7rHXY2Ff9?g_st=ic" target='_blank'><p className="clr_change_text ind_text_swd lh-base">Mandli Garba 2024, near Ognaj toll tax, <br />off S.P ring road, Ahmedabad, Gujarat. <MdOutlineArrowOutward style={{ color: 'var(--primaryYellow)' }} /></p></NavLink></div>
            </div>
          </div>
          <div className='main-heading pt-2 new-btn-style'>
            <NavLink className="common-btn white cst-font-size gallry_filed og-font-style" to="/buy-now" ><span>Book Passes</span></NavLink>
          </div>
        </div>
      </section>

      <section className={`section whenWhere-section hideinDesktop`} id='formats'>
        <div className="box-width-block">
          <div className="container pb-0">
            <div className='heading-addon2024'>
              <h1 className="and-fonts h-one clr_change addon2024">Mandli Garba</h1>
              <p>Navratri 2024 at its best</p>
            </div>

          </div>
          <div className='container pb-0'>
            <div className='main-heading cst-font-spc-bt'>
              <h2 className="and-fonts h-one clr_change">when &</h2>
              <h2 className="and-fonts h-two "> where</h2>
            </div>
            <div className='main-heading'>
              <h3 className="subheading">dates</h3>
              <p className="clr_change_text">3<sup>rd</sup> to 12<sup>th</sup> Oct 2024<br />08:00 PM onwards</p>
            </div>
            <div className='main-heading'>
              <h3 className="subheading">venue</h3>
              {/* <p className="clr_change_text"><NavLink to="https://maps.app.goo.gl/cqRpAZUdkpGEBYMU7" target='_blank'>Mandli Garba 2023, SOM Greens, near Amaryllis crossing,<br />Canal Road, Shilaj, Ahmedabad, Gujarat - 380059. <AiOutlineLink /></NavLink></p> */}
              {/* <p className="clr_change_text">Mandli Garba 2024, near Ognaj Toll Tax, <br />off Sardar Patel Ring Road, <br />Ahmedabad, Gujarat 380060</p> */}
              <div className="mxcontetn"><NavLink to="https://maps.app.goo.gl/A5rYKSRo7rHXY2Ff9?g_st=ic" target='_blank'><p className="clr_change_text ind_text_swd lh-base">Mandli Garba 2024, near Ognaj toll tax, <br />off S.P ring road, Ahmedabad, Gujarat. <MdOutlineArrowOutward style={{ color: 'var(--primaryYellow)' }} /></p></NavLink></div>
            </div>
            <div className='main-heading pt-2 mobile'>
              <NavLink className="common-btn big cst-font-size" to="/buy-now" ><span>Book Passes</span></NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className='section about-section hideinmobile-section' id='Whatismandli'>
        <div className='container'>
          <div className='row'>
            <div className='left'>
              <div className='main-heading what_is_mandli'>
                <h2 className="and-fonts ">what is <span>mand<span>li<sup>&reg;</sup></span></span></h2>
                <p className='ind_text_swd lineheight-spc lh-base'>Mandli is a design-first event that hosts the most awaited Sheri - Style Garba. The initiative of a group of friends, and now CEPT alumni, commemorates the love, passion and zeal for garba into a shared and immersive ecosystem.</p>
                {/* <p className='ind_text_swd lineheight-spc '>A Designer's dream and a Khelaiya's most desired. </p> */}
                <p className='ind_text_swd lineheight-spc '>An experience curated through <br />Design installations, Dhol & Shehnai<br /> and your love! </p>
                <p className='ind_text_swd lineheight-spc '>Aavo Fari Jodaiye!!</p>
                <div className='main-heading pt-2 new-btn-style pt-4'>
                  <NavLink className="common-btn white cst-font-size gallry_filed og-font-style" to="/about" ><span>Know More</span></NavLink>
                </div>
              </div>
            </div>
            {/* <div className='right'>
              <img src={AboutImg} alt="" />
            </div> */}
          </div>
        </div>
      </section>


      <section className='section formats-section' id='galleryog'>
        <div className='container'>
          <div className="addpsaceIndesktop">
            <div className='main-heading what_is_mandli'>
              <h2 className="and-fonts">glimpses from past</h2>
            </div>
            <div className='image-gallry '>
              <ImageSlider
                images={[img01, img02, img03, img04, img05, img06, img07, img08, img09, img10]}
                slidesToShowDesktop={3}
                slidesToShowTablet={3}
                slidesToShowMobile={1}
              />

            </div>
          </div>
        </div>
        <div className='main-heading mt-5'>
          <div className='container new-btn-style'>
            <NavLink className="common-btn white big cst-font-size gallry_filed og-font-style" to="/gallery" ><span>View Gallery</span></NavLink>
          </div>

        </div>
      </section>

      <section className='faqs-sec'>
        <div className='container'>
          <div className="addpsaceIndesktop">
            <h2 className="and-fonts">Frequently Asked Questions</h2>
            <div className="mobile-faq-container">
              {getFaqsList.map((faq, index) => (
                <div
                  className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                  key={index}
                  onClick={() => toggleFAQ(index)}
                >
                  <div className="faq-question">
                    {faq.question}
                    <span className={`arrow ${activeIndex === index ? 'rotate' : ''}`}>
                      <IoChevronDown />
                    </span>
                  </div>
                  <div className="faq-ans" data-index={index} >
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home2024





