// import React from 'react';
import React, { useEffect, useState } from 'react';
import "./Home.css"
import { NavLink, useNavigate } from "react-router-dom";
import AnimationImg from '../../Images/Mandli/ANiScroll.png'
import Beforefooter from '../../Images/Mandli/Before-Footer.png'
import Popup from '../../Components/Popup/Popup';
import BannerImage from '../../Images/Mandli/mainBanner.png'
import BannerImageMo from '../../Images/Mandli/Banner-md.png'
import AboutImg from '../../Images/Mandli/about.png'
import { AiOutlineLink } from 'react-icons/ai';
import img01 from '../../Images/2022/01.jpg'
import img02 from '../../Images/2022/28.jpg'
import img03 from '../../Images/2022/03.jpg'
import img04 from '../../Images/2022/04.jpg'
import img05 from '../../Images/2022/23.jpg'
import img06 from '../../Images/2022/06.jpg'
import img07 from '../../Images/2022/07.jpg'
import img08 from '../../Images/2022/15.jpg'
import img09 from '../../Images/2022/13.jpg'
import img10 from '../../Images/2022/10.jpg'
import arrow from '../../Images/location_arrow.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination } from 'swiper/modules';
import Lottie from 'lottie-react';
// import LotiAnimation from '../../Images/Mandli/Lottie/WAVE-Desktop.json'
// import LotiAnimationMobile from '../../Images/Mandli/Lottie/WAVES-Mobile.json'
import LotiAnimation from '../../Images/Mandli/Lottie/WAVE-V1-Desktop.json'
import LotiAnimationMobile from '../../Images/Mandli/Lottie/WAVE-V1-Mobile.json'
import ImageSlider from '../../Components/ImageSlider/ImageSlider';

const itemsa = [
  {
    id: 1,
    imageUrl: img01,
  },
  {
    id: 2,
    imageUrl: img02,
  },
  {
    id: 3,
    imageUrl: img03,
  },
  {
    id: 4,
    imageUrl: img04,
  },
  {
    id: 5,
    imageUrl: img05,
  },
  {
    id: 6,
    imageUrl: img06,
  },
  {
    id: 7,
    imageUrl: img07,
  },
  {
    id: 8,
    imageUrl: img08,
  },
  {
    id: 9,
    imageUrl: img09,
  },
  {
    id: 10,
    imageUrl: img10,
  },
];


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const imageCount = 8; // Number of images
  const images = Array.from({ length: imageCount }, (_, index) => {
    return `demo${index % 2 + 1}`; // Assuming demo1 and demo2 alternate
  });

  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImage(
      (prevImage) => (prevImage - 1 + images.length) % images.length
    );
  };


  return (
    <div className='Home'>
      <Popup />
      <div className="banner">
        <div className="bannerImage">
          {/* <img className='desktopOnly' src={BannerImage} alt="" />
          <img className='mobileOnly' src={BannerImageMo} alt="" /> */}
          <Lottie className='desktopOnly lottie_animation' animationData={LotiAnimation} loop={true} />
          <Lottie className='mobileOnly lottie_animation' animationData={LotiAnimationMobile} loop={true} />

        </div>
        <div className="container banner-content">
          <div className='banner-text'>
            <h1 className='titleDesktop'><span>Mandli Garba</span> <br /> Navratri 2024 <br />at its best</h1>
            <h1 className='titleMobile'>Ahmedabad's most awaited <br /><span className='big'><span>Garba</span> experience!</span></h1> 
            {/* <h1 className='titleDesktop'>Ahmedabad's <br />most awaited <br /><span>Garba</span> experience!</h1>
            <h1 className='titleMobile'>Ahmedabad's most awaited <br /><span className='big'><span>Garba</span> experience!</span></h1> */}
          </div>
        </div>
      </div>

      <section className='section whenWhere-section' id='formats'>
        <img src={AnimationImg} alt="" />
        <div className='container'>
          <div className='main-heading cst-font-spc-bt'>
            <h2 className="and-fonts h-one clr_change">when &</h2>
            <h2 className="and-fonts h-two "> where</h2>
          </div>
          <div className='main-heading'>
            <h3 className="subheading">dates</h3>
            <p className="clr_change_text">3rd Oct. to 12th Oct. 2024<br />8 P.M. onwards</p>
          </div>
          <div className='main-heading'>
            <h3 className="subheading">venue</h3>
            {/* <p className="clr_change_text"><NavLink to="https://maps.app.goo.gl/cqRpAZUdkpGEBYMU7" target='_blank'>Mandli Garba 2023, SOM Greens, near Amaryllis crossing,<br />Canal Road, Shilaj, Ahmedabad, Gujarat - 380059. <AiOutlineLink /></NavLink></p> */}
            <p className="clr_change_text">Mandli ground, before Gala <br /> Goldcrest, Canalroad, Bhadaj, <br /> 380060 </p>
           
          </div>
          <div className='main-heading pt-2 mobile'>
            <NavLink className="common-btn big cst-font-size" to="/buy-now" ><span>Book Passes</span></NavLink>
          </div>
        </div>
      </section>


      <section className='section about-section' id='Whatismandli'>
        <div className='container'>
          <div className='row'>
            <div className='left'>
              <div className='main-heading what_is_mandli'>
                <h2 className="and-fonts">what is <span>mand<span>li<sup>&reg;</sup></span></span></h2>
                <p>At Mandli, modern society merges with the timeless tradition of ‘Sheri Garba’, enhanced by the beauty of design—a legacy we’ve cherished since our CEPT University days.</p>
                <p className='short'>Join us in welcoming the Goddess of power with lights, music, and celebration. </p>
                <p>Jaldi padharo garba ramva!</p>
                <div className='main-heading pt-2'>
                  <NavLink className="common-btn white cst-font-size gallry_filed" to="/buy-now" ><span>Book Passes</span></NavLink>
                </div>
              </div>
            </div>
            <div className='right'>
              <img src={AboutImg} alt="" />
            </div>
          </div>
        </div>
      </section>


      <section className='section formats-section' id='gallery'>
        <div className='container'>
          <div className='main-heading what_is_mandli'>
            <h2 className="and-fonts">glimpses from 2023</h2>
          </div>
        </div>
        <div className='image-gallry '>
          <ImageSlider
            images={[img01, img02, img03, img04, img05, img06, img07, img08, img09, img10]}
            slidesToShowDesktop={5}
            slidesToShowTablet={3}
            slidesToShowMobile={1}
          />
          {/* <Swiper
            slidesPerView={5}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            centeredSlides={true}
            // autoplay={{
            //   delay: 1500,
            //   disableOnInteraction: false,
            // }}
            modules={[Autoplay]}
            className="mySwiper"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
          >
            {itemsa.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.imageUrl} alt={`Slide ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper> */}
        </div>
        <div className='main-heading mt-5'>
          <div className='container'>
            <NavLink className="common-btn big cst-font-size gallry_filed" to="/gallery" ><span>View Gallery</span></NavLink>
          </div>
        </div>
      </section>


      <section className='footer-before-section'>
        <img src={Beforefooter} alt />
      </section>

    </div>
  )
}

export default Home
