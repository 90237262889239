import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MandliLogo from '../../Images/Mandli/mandli-yellow.svg'
import wheel from '../../Images/Mandli2024/MainBg2024.png'

const LocationQR = ({ eventLabel }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (window.gtag) {
            console.log("GA Event" , eventLabel);
            window.gtag('event', 'Location_QR_Scan', {
                event_category: 'Location_QR_Scan',
                event_label: eventLabel,
            });
        }

        setTimeout(() => {
            navigate('/');
        }, 1000);
    }, [eventLabel]);

    return (
        <div className='comming-soon error'>
            <div class="mandli">
                <img src={MandliLogo} width="250px" style={{ margin: "0 0 20px" }} alt="Mandli Logo" />
                <p>03<sup>rd</sup> to 12<sup>th</sup> October</p>
            </div>
            <div class="wheel">
                <img src={wheel} alt="Animation" />
            </div>
        </div>
    );
};

export default LocationQR;
